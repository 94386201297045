import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import PageTitle from '../components/PageTitle'
import Container from '../components/Container'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const Text = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidthCentered};

  p {
    margin: 0 0 2rem 0;
    font-size: 1.125rem;
    line-height: 1.555555;
    color: ${props => props.theme.colors.grey_900};
  }

  a {
    transition: 0.2s;
    color: ${props => props.theme.colors.text};
    font-weight: 600;

    &:hover {
      color: ${props => props.theme.colors.primary};
    }
  }
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" description="Page Not Found" />
    <Container>
      <PageTitle>Page Not Found</PageTitle>
      <Text>
        <p>
          Please return <Link to="/">home</Link> or use the menu to navigate to
          a different page.
        </p>
      </Text>
    </Container>
  </Layout>
)

export default NotFoundPage
